import Swiper from 'swiper'
import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Grid,
  Thumbs,
} from 'swiper/modules'
import { media } from './functions/media'

declare global {
  interface Window {
    Swiper: typeof Swiper
  }
}

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs])
Swiper.defaults.touchStartPreventDefault = false
window.Swiper = Swiper

export default (): void => {
  new window.Swiper('.stages-slider .swiper', {
    pagination: {
      el: '.stages-slider .swiper-pagination',
      clickable: true,
    },
    slidesPerView: 1.2,
    slidesPerGroup: 1,
    grabCursor: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 2,
      },
      [media.md]: {
        slidesPerView: 3,
      },
      [media.lg]: {
        slidesPerView: 4,
      },
      [media.xl]: {
        slidesPerView: 5,
      },
    },
  }) as Swiper

  new window.Swiper('.gift-slider .swiper', {
    pagination: {
      el: '.gift-slider .swiper-pagination',
      clickable: true,
    },
    slidesPerView: 1.2,
    slidesPerGroup: 1,
    spaceBetween: 12,
    grabCursor: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1.6,
        spaceBetween: 16,
      },
      [media.md]: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      [media.lg]: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
      [media.xl]: {
        slidesPerView: 4,
        spaceBetween: 28,
      },
    },
  }) as Swiper

  new window.Swiper('.about-slider .swiper', {
    pagination: {
      el: '.about-slider .swiper-pagination',
      clickable: true,
    },
    slidesPerView: 1.2,
    slidesPerGroup: 1,
    spaceBetween: 12,
    grabCursor: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1.6,
        spaceBetween: 16,
      },
      [media.md]: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      [media.lg]: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
      [media.xl]: {
        slidesPerView: 4,
        spaceBetween: 28,
      },
    },
  }) as Swiper
}
